<template>
  <div class="-info">
    <div class="c_main -pad-btm">
			<div class="inner">
				<nav class="c_pnkz">
					<ul class="c_pnkz_list">
						<li><a class="c_pointer" @click="$router.push({ name: 'Home'})">ホーム</a></li>
						<li>JC3からのお知らせ</li>
					</ul>
				</nav>
				<section class="info">
					<h1 class="info_ttl">JC3からのお知らせ</h1>
					<div class="info_list">
						<article v-for="info in infos" :key="info.id" class="info_item">
							<a class="info_item_wrap" @click="$router.push({name:'InfoSingle', params: {id: info.id}})">
								<div class="info_item_info"><time>{{ info.start_datetime | formatDateDot }}</time></div>
								<h2 class="info_item_ttl">{{ info.title }}</h2>
							</a>
						</article>
					</div>
          <div :class="{c_load: loading}"></div>
					<div v-if="!loading && infos.length===0" style="text-align: center; padding:20px;">
            お知らせはありません。
          </div>
					<paginate
            v-if="infos.length"
						v-model="page"
            :page-count="getPageCount"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'&lt; 前へ'"
            :next-text="'次へ &gt;'"
            :container-class="'c_pager'"
						:prev-link-class="'-prev'"
						:next-link-class="'-next'"
						no-li-surround
            >
          </paginate>
				</section>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
			infos: [],
			total: 0,
			loading: false,
			page: 1,
    }
	},
	computed: {
		getPageCount: function() {
      return Math.ceil(this.total / 5);
		},
	},
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
			this.infos = []
      this.loading = true

      let params = { page: this.page }
      this.$authAxios.get('/api/info', {
				params: params
			})
      .then((response) => {
        if (response.data.results.length) {
					this.infos = response.data.results
					this.total = response.data.count
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
		},
		clickCallback(pageNum) {
      window.scrollTo({top:0, behavior: "instant"})
			this.page = Number(pageNum)
			this.fetch()
		}
  }
}
</script>

<style scoped>
@import "~@/css/info.css";
</style>